const BASE_STATUS_REPO = {
  0: "Mới khởi tạo",
  1: "Đang hoạt động",
  2: "Khóa",
};

const BASE_CUSTOMER_STATUS_OPTIONS = [
  {
    label: "Mới đăng ký",
    value: 1,
  },
  {
    label: "Đã duyệt",
    value: 2,
  },
  {
    label: "Không được duyệt",
    value: 3,
  },
];
const BASE_CUSTOMER_STATUS_VALUES = {
  NEW: 1,
  ACTIVATE: 2,
  DEACTIVATE: 3,
};
const BASE_CUSTOMER_STATUS_REPO = {
  1: "Tạo mới",
  2: "Đã duyệt",
  3: "Không được duyệt",
};

export {
  BASE_STATUS_REPO,
  BASE_CUSTOMER_STATUS_OPTIONS,
  BASE_CUSTOMER_STATUS_VALUES,
  BASE_CUSTOMER_STATUS_REPO,
};
