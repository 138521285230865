const parserVnd = (value) => {
  return isNaN(parseInt(value)) ? 0 : `${value}`.replace(/\D|\s/g, "");
};

const formatterVnd = (value) => {
  return isNaN(parseInt(value))
    ? ""
    : Intl.NumberFormat("vi-VN", { currency: "VND" }).format(
        parseInt(`${value}`.replace(" VNĐ", ""))
      );
};

const formatterVndDisplay = (value) => {
  return isNaN(parseInt(value))
    ? ""
    : Intl.NumberFormat("vi-VN", { currency: "VND" }).format(
        parseInt(`${value}`.replace(" VNĐ", ""))
      ) + " VNĐ";
};

const isVietnamesePhoneNumber = (number) => {
  return /(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/.test(number);
};

export {
  parserVnd,
  formatterVnd,
  formatterVndDisplay,
  isVietnamesePhoneNumber,
};
