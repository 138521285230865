import { Table, Typography } from "antd";
import classNames from "classnames";

const { Text } = Typography;

/**
 * KTInfoTable
 *
 * @param {*} dataSource {label: "", value: "" | node }[]
 * @returns
 */
const KTInfoTable = ({ className, ...rest }) => {
  return (
    <Table className={classNames(["kt-info-table", className])} {...rest} />
  );
};

export default KTInfoTable;
