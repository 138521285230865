import React from "react";
import { PageHeader } from "antd";
import classNames from "classnames";

const KTInfoHeader = ({ className, hasBorderTop, ...rest }) => {
  return (
    <PageHeader
      className={classNames([
        "kt-info-header",
        className,
        hasBorderTop ? "kt-info-header-has-border-top" : null,
      ])}
      {...rest}
    />
  );
};

export default KTInfoHeader;
