import React from "react";
import Head from "next/head";
import { PageHeader, Space, Table } from "antd";
import LayoutDefault from "../../../components/Layout";
import { getFilterValuesHelper, getPaginationTotalInfo } from "../../../cores";
import { capitalizeFirstLetter } from "../../../cores";

class KTList extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    loading: false,
    items: [],
    detailItem: null,
    infoItem01: null,
    filterType: null,
    filter: {},
    sorter: {},
    pagination: {
      PageNumber: 1,
      PageSize: 10,
      total: 0,
    },
  };

  componentDidMount() {
    if (!this.initFilter) {
      throw new Error(
        "Please set this.initFilter in child class or if you don't use default value, please set value is {}"
      );
    }

    if (this.initSorter) {
      throw new Error(
        "Please set this.initFilter in child class or if you don't use default value, please set value is {}"
      );
    }

    this.loadData();
  }

  loadData() {
    if (typeof this.listRequest === "function") {
      this.requestList(
        this.listRequest,
        this.requestConfig,
        this.requestHeaders,
        this.requestParameters
      );
    }
  }

  requestConfig = {};
  requestHeaders = {};

  /**
   * Is Object or Function: () => { return Object };
   */
  requestParameters = {};

  initFilter = null;
  fixedFilter = {};

  _searchFormRef = React.createRef();
  _addModalRef = React.createRef();
  _detailModalRef = React.createRef();
  _editModalRef = React.createRef();

  _itemInfo01ModalRef = React.createRef();

  openAddModal = () => {
    this._addModalRef?.current?.open();
  };

  closeAddModal = () => {
    this._addModalRef?.current?.close();
  };

  // Detail modal
  openDetailModal = () => {
    this._detailModalRef?.current?.open();
  };

  closeDetailModal = () => {
    this.setState({ detailItem: null });
    this._detailModalRef?.current?.close();
  };

  showDetailItem = (item) => {
    this.setState(
      {
        detailItem: item,
      },
      () => {
        this.openDetailModal();
      }
    );
    // console.log(this.state.detailItem);
  };

  openEditModal = () => {
    this._editModalRef?.current?.open();
  };

  closeEditModal = () => {
    this._editModalRef?.current?.close();
  };

  // Info 01 modal
  openInfoItem01Modal = () => {
    this._itemInfo01ModalRef?.current?.open();
  };

  closeInfoItem01Modal = () => {
    this.setState({ infoItem01: null });
    this._itemInfo01ModalRef?.current?.close();
  };

  showInfoItem01Modal = (item) => {
    this.setState(
      {
        infoItem01: item,
      },
      () => {
        this.openInfoItem01Modal();
      }
    );
  };

  columns = [];

  /**
   * Hàng nút trên Table
   * Gọi hàng this.addAction() để thêm 1 action vào ds
   */
  mainActions = [];

  /**
   * Function request api
   */
  listRequest;

  /**
   * Tên danh sách
   * Ví dụ: "Danh sách t"
   */
  title = "==> Please override in Child Class <==";
  extraFilter = {};

  setFilterType(value) {
    this.setState({ filterType: value });
  }

  setFilterValue = async (type, value) => {
    this.setState({
      filter: { [type]: value },
    });
  };

  resetFilterValue = async () => {
    this.setState({
      filter: this.initFilter,
    });
  };

  setFilterValues = async ({ value }) => {
    this.setState({
      filter: { ...(value || {}) },
    });
  };

  resetFilterValues = async () => {
    this.setState({
      filter: {},
    });
  };

  reloadList = () => {
    this.loadData();
  };

  resetAndReloadList = () => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          PageNumber: 1,
          total: 0,
        },
        items: [],
      },
      () => {
        this.loadData();
      }
    );
  };

  setFilterAndReloadList = (values) => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          PageNumber: 1,
          total: 0,
        },
        items: [],
        filter: values,
      },
      () => {
        this.loadData();
      }
    );
  };

  resetFilterAndReloadList = () => {
    this.setState(
      {
        pagination: {
          ...this.state.pagination,
          PageNumber: 1,
          total: 0,
        },
        items: [],
        filterType: null,
        filter: this.initFilter || {},
      },
      () => {
        this.loadData();
      }
    );
  };

  resetFilter = () => {
    this.setState({ filterType: null, filter: {} });
  };

  addToMainActions(node) {
    this.mainActions.push(node);
  }

  // Xử lý dữ liệu items sau khi trả về
  transformResponseDataList;

  // Xử lý dữ liệu totalRecords sau khi trả về
  transformResponseTotalList;

  getSorterValue = (sorter) => {
    if (sorter.field) {
      if (sorter.order) {
        return {
          sort: sorter.order === "ascend" ? "asc" : "desc",
          order: sorter.field,
        };
      }

      return {};
    }

    if (this.initSorter.field) {
      if (sorter.order) {
        return {
          sort: sorter.order === "ascend" ? "asc" : "desc",
          order: sorter.field,
        };
      }
    }

    return {};
  };

  /**
   *
   * @param {*} apiRequest Request
   * @param {*} options
   * @param {*} headers
   * @param {*} transform Transform response data
   */
  async requestList(
    listRequest,
    requestConfig = {},
    requestHeaders = {},
    requestParameters = {}
  ) {
    const query = {
      ...getFilterValuesHelper({
        ...(Object.keys(this.state.filter).length
          ? this.state.filter
          : this.initFilter || {}),
        ...this.fixedFilter,
      }),
      PageSize: this.state.pagination.PageSize,
      PageNumber: this.state.pagination.PageNumber,
      ...this.getSorterValue(this.state.sorter),
    };

    const parameters =
      typeof requestParameters === "function"
        ? requestParameters()
        : requestParameters;

    this.setState({ loading: true });
    try {
      const res = await listRequest({
        query,
        requestConfig,
        requestHeaders,
        requestParameters: parameters,
      });
      this.setState({
        items:
          typeof this.transformResponseList === "function"
            ? this.transformResponseList(res)
            : res.data.data.records,
        pagination: {
          ...this.state.pagination,
          total:
            typeof this.transformResponseTotalList === "function"
              ? this.transformResponseTotalList(res)
              : res.data.data.totalRecords,
        },
        rawData: res.data.data,
      });
    } catch (error) {
      console.log(error);
    }
    this.setState({ loading: false });
  }

  renderSearchBar() {
    return null;
  }

  renderActionsBar() {
    return null;
  }

  renderTop() {
    return <>{this.renderSearchBar()}</>;
  }

  renderBottom() {
    console.log("KTList warning: renderBottom in parent");
    return null;
  }

  renderFilter() {
    return null;
  }

  renderOtherComponent() {
    return null;
  }

  processColumns() {
    return this.columns;
  }

  getPaginationTotalInfo = getPaginationTotalInfo;

  renderTable() {
    const { items, loading, filter, pagination } = this.state;

    return (
      <Table
        columns={this.processColumns()}
        rowKey="id"
        dataSource={items}
        loading={loading}
        pagination={{
          current: pagination.PageNumber,
          pageSize: pagination.PageSize,
          total: pagination.total,
          showSizeChanger: true,
          showTotal: getPaginationTotalInfo,
        }}
        scroll={{ x: 1024 }}
        onChange={(pagination, filters, sorter, extra) => {
          this.setState(
            {
              pagination: {
                ...pagination,
                PageNumber: pagination.current,
                PageSize: pagination.pageSize,
              },
              sorter,
            },
            () => {
              this.loadData();
            }
          );
        }}
        locale={{
          emptyText: this.state.loading
            ? "Đang tải dữ liệu"
            : `Không có dữ liệu${
                Object.keys(filter).length
                  ? ", thay đổi bộ lọc để tếp tục tìm kiếm."
                  : "."
              }`,
          pagination: {},
        }}
      />
    );
  }

  renderContent() {
    return <>{this.renderTable()}</>;
  }

  renderPageHeader() {
    return <PageHeader title={this.title} extra={this.renderActionsBar()} />;
  }

  renderFistTop() {
    return <></>;
  }

  renderTitle() {
    return (
      <Head>
        <title>{this.title}</title>
      </Head>
    );
  }

  render() {
    // console.log(this.state, "state");

    return (
      <>
        {this.renderTitle()}
        <Space direction="vertical" className="full-width" size={16}>
          {this.renderFistTop()}
          {this.renderPageHeader()}
          {this.renderTop()}
          {this.renderContent()}
          {this.renderBottom()}
        </Space>
        {this.renderOtherComponent()}
      </>
    );
  }
}

KTList.getLayout = function getLayout(page) {
  return <LayoutDefault>{page}</LayoutDefault>;
};

export default KTList;
