import { Table, Typography } from "antd";
import classNames from "classnames";

const { Text } = Typography;

/**
 * KTObjectInfo
 *
 * @param {*} dataSource {label: "", value: "" | node }[]
 * @returns
 */
const KTObjectInfo = ({
  className,
  dataSource,
  labelWidth = 150,
  style = {},
}) => {
  const columns = [
    {
      dataIndex: "label",
      width: labelWidth,
      render: (_, { label }) => <Text strong>{label}</Text>,
    },
    {
      dataIndex: "value",
    },
  ];

  const data = dataSource.map((item, index) => {
    return {
      _rowKey: index + 1,
      ...item,
    };
  });

  return (
    <Table
      rowKey="_rowKey"
      className={classNames(["kt-object-info", className])}
      style={{ padding: "0 0px", ...style }}
      columns={columns}
      dataSource={data}
      pagination={false}
      // bordered
      showHeader={false}
    />
  );
};

export default KTObjectInfo;
