const removeEmptyInObject = ({
  obj,
  trimBeforeRemove = false,
  removeUndefined = false,
  removeNull = false,
  removeEmptyString = false,
}) => {
  const data = { ...obj };
  Object.keys(data).forEach((key) => {
    if (removeUndefined && data[key] === undefined) {
      delete data[key];
    }

    if (removeNull && data[key] === null) {
      delete data[key];
    }

    if (trimBeforeRemove) {
      if (typeof data[key] === "string") data[key] = data[key].trim();
    }

    if (removeEmptyString && data[key] === "") {
      delete data[key];
    }
  });

  return data;
};

const getFilterValuesHelper = (values) => {
  try {
    return removeEmptyInObject({
      obj: values,
      trimBeforeRemove: true,
      removeUndefined: true,
      removeNull: true,
      removeEmptyString: true,
    });
  } catch (error) {
    return values;
  }
};

export { removeEmptyInObject, getFilterValuesHelper };
