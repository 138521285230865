import React, { useState } from "react";
import { useEffect } from "react";
import { Row, Col } from "antd";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import Link from "next/link";
import { fetchWrapper } from "../../service";
import ReactHtmlParser from "react-html-parser";
import { formatDateTime } from "../../cores";

export default function Home() {
  const [info, setInfo] = useState({});
  const [videoUrl, setVideoUrl] = useState();
  const [news, setNews] = useState([]);

  const getInfo = async () => {
    const t = await fetchWrapper.getCustomerInfo();
    if (t.video) {
      let v = t.video.toString().split("?v=");
      if (v.length > 1) {
        setVideoUrl("https://www.youtube.com/embed/" + v[1]);
      }
    }
    setInfo(t);
  };

  const getNewsList = async () => {
    const response = await fetchWrapper.get(`publish/news`);
    if (response?.status === 200 && response?.data?.success) {
      setNews(response.data.rows);
    }
    return [];
  };

  useEffect(() => {
    getInfo();
    getNewsList();
  }, []);

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <>
      <Row>
        <Col xs={24} sm={24} md={18}>
          <Col className="card mb-20">
            <Col className="customer-info-text">
              <span>THÔNG TIN DOANH NGHIỆP</span>
            </Col>
            <Col className="customer-info-name">{info.name}</Col>
            <p className="content-login">
              {info.introduction
                ? ReactHtmlParser(info.introduction.content)
                : null}
            </p>
            {videoUrl ? (
              <div className="mb-20">
                <figure class="media">
                  <Col>
                    <Col
                      style={{
                        position: "relative",
                        height: "0",
                        paddingBottom: "56.2493%",
                      }}
                    >
                      <iframe
                        src={videoUrl}
                        style={{
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                          top: "0",
                          left: "0",
                        }}
                        frameborder="0"
                        allow="autoplay; encrypted-media"
                        allowfullscreen=""
                      ></iframe>
                    </Col>
                  </Col>
                </figure>
              </div>
            ) : (
              <></>
            )}
            <Row className="">
              {info.introduction && info.introduction.images ? (
                info.introduction.images.map((item, idx) => (
                  <Col xs={24} sm={12} md={6}>
                    <Col className="mb-20 mr-10">
                      <img src={item.url_thumbnail} width={"100%"} />
                    </Col>
                  </Col>
                ))
              ) : (
                <></>
              )}
            </Row>
          </Col>
          <Col xs={24} sm={24} md={24}>
            <Col className="customer-profile card">
              <Col className="customer-info-text">
                <span>HỒ SƠ DOANH NGHIỆP</span>
              </Col>
              <Col className="customer-property">
                <Col className="customer-name">{info.name}</Col>
                <Col className="customer-info">
                  <Col className="mb-10">Mã số thuế: {info.tax_code}</Col>
                  <Col className="mb-10">Mã GCP: {info.gcp_code}</Col>
                  <Col className="mb-10">Mã GLN: {info.gln_code}</Col>
                  <Col className="mb-10">{info.address}</Col>
                  <Col>
                    <Carousel
                      className="carousel"
                      swipeable={false}
                      draggable={false}
                      showDots={true}
                      responsive={responsive}
                      ssr={true} // means to render carousel on server-side.
                      // infinite={true}
                      autoPlay={true}
                      autoPlaySpeed={3000}
                      keyBoardControl={true}
                      customTransition="all .5"
                      transitionDuration={500}
                      containerClass="carousel-container"
                      removeArrowOnDeviceType={["tablet", "mobile"]}
                      dotListClass="custom-dot-list-style"
                      itemClass="carousel-item-padding-40-px"
                    >
                      {info.images ? (
                        info.images.map((item, idx) => (
                          <div className="carousel-item">
                            <img src={item.url_thumbnail} width={"100%"} />
                          </div>
                        ))
                      ) : (
                        <></>
                      )}
                    </Carousel>
                  </Col>
                </Col>
              </Col>
            </Col>
          </Col>
        </Col>
        <Col xs={24} sm={24} md={6}>
          <Col className="card">
            <p className="news-text">TIN TỨC</p>

            {news ? (
              news.map((item, idx) => (
                <Col className="news">
                  <Col className="news-image">
                    <Link href={`/news/` + item.id}>
                      <a>
                        {item.image ? (
                          <img
                            src={JSON.parse(item.image).url_thumbnail}
                            width={"100%"}
                          />
                        ) : (
                          <></>
                        )}
                      </a>
                    </Link>
                  </Col>
                  <Col className="news-date">
                    {formatDateTime(item.updated_at)}
                  </Col>
                  <Col className="news-title">
                    <Link href={`/news/` + item.id}>
                      <a>{item.title}</a>
                    </Link>
                  </Col>
                  <Col className="news-description">{item.description}</Col>
                  <Col className="news-link">
                    <Link href={`/news/` + item.id}>
                      <a>Xem TIN</a>
                    </Link>
                  </Col>
                </Col>
              ))
            ) : (
              <></>
            )}
          </Col>
        </Col>
      </Row>
    </>
  );
}
