import React from "react";
import { Typography } from "antd";
import classNames from "classnames";

const { Title } = Typography;

const KTTitle = ({ children, isSection = false, className, ...rest }) => {
  return (
    <Title
      className={classNames([
        className,
        {
          "kt-title": true,
          "kt-title-section": isSection,
        },
      ])}
      {...rest}
    >
      {children}
    </Title>
  );
};

export default KTTitle;
