import React from "react";
import { Typography } from "antd";

const { Text, Link } = Typography;
const KT_BADGE_COLOR_TYPE_REPO = {
  default: "#29AA46",
  primary: "#1890ff",
  success: "#29aa46",
  warning: "#faad14",
  danger: "#f5222d",
  error: "#f5222d",
  newly: "#722ed1",
  draft: "#13c2c2",
};

const KTBadge = ({
  children,
  text,
  type = "default",
  color,
  className,
  ...rest
}) => {
  return <Text type={type}>{text}</Text>;
};

export default KTBadge;
