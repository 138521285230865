import moment from "moment";

const formatDate = (date) => {
  try {
    return moment(date).format("DD/MM/YYYY");
  } catch (error) {
    return "-";
  }
};

const formatDateTime = (dateTime) => {
  try {
    if (dateTime) {
      return moment(dateTime)
        .format("DD/MM/YYYY HH:mm")
        .replace("00:00", "")
        .trim();
    }
    return "--";
  } catch (error) {
    return "-";
  }
};

const getPeriod = (startTime, endTime, widthPrefix = false) => {
  try {
    if (!startTime || !endTime) {
      return "-";
    }
    const diffYear = moment(endTime).diff(startTime, "year");
    const diffMonth = moment(endTime).diff(startTime, "month");
    if (diffMonth % 12 === 0) {
      return diffYear + (widthPrefix ? " (năm)" : "");
    }

    return diffMonth + (widthPrefix ? " (tháng)" : "");
  } catch (error) {
    return "-";
  }
};

export { formatDate, formatDateTime, getPeriod };
