import React from "react";
import { Modal, Row, Typography, Col, Button } from "antd";
import { render, unmountComponentAtNode } from "react-dom";

const { Text } = Typography;

const KTConfirmModal = ({
  title,
  content,
  onOk,
  okText,
  cancelText,
  okType,
  confirmLoading,
  cancelType,
  showOk = true,
  showCancel = true,
}) => {
  const onClose = () => {
    removeConfirmModal();
  };

  const handleOKButton = (button) => {
    if (onOk) {
      onOk();
    }
    onClose();
  };

  return (
    <div id="kt-confirm">
      <Modal
        wrapClassName="kt-confirm"
        width={640}
        title={title ? title : "Xác nhận"}
        visible={true}
        confirmLoading={confirmLoading}
        onCancel={() => onClose()}
        centered
        footer={[
          showOk ? (
            <Button
              key={"ok"}
              type={okType ? okType : "success"}
              onClick={handleOKButton}
              style={{ marginRight: 8, width: 100 }}
            >
              {okText ? okText : "Có"}
            </Button>
          ) : null,
          showCancel ? (
            <Button
              key={"cancel"}
              type={cancelType ? cancelType : "default"}
              onClick={onClose}
              style={{ width: 100 }}
            >
              {cancelText || "Không"}
            </Button>
          ) : null,
        ].filter((i) => !!i)}
      >
        <Row>{content}</Row>
        <Row justify="end" style={{ marginTop: 15 }}></Row>
      </Modal>
    </div>
  );
};

const createConfirmModal = (properties) => {
  let divTarget = document.getElementById("kt-confirm");
  if (divTarget) {
    render(<KTConfirmModal {...properties} />, divTarget);
  } else {
    divTarget = document.createElement("div");
    divTarget.id = "kt-confirm";
    document.body.appendChild(divTarget);
    render(<KTConfirmModal {...properties} />, divTarget);
  }
};

const removeConfirmModal = () => {
  const target = document.getElementById("kt-confirm");
  if (target) {
    unmountComponentAtNode(target);
    target.parentNode.removeChild(target);
  }
};

export const KTConfirm = (properties) => {
  createConfirmModal(properties);
};

export default KTConfirm;
