import React from "react";
import { Typography } from "antd";
import classNames from "classnames";

const { Text } = Typography;

const KTText = ({ children, className, ...rest }) => {
  return (
    <Text className={classNames(["kt-text", className])} {...rest}>
      {children}
    </Text>
  );
};

export default KTText;
