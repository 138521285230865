import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Head from "next/head";
import {
  Row,
  Col,
  Button,
  Modal,
  Form,
  Input,
  Switch,
  Space,
  notification,
  message,
} from "antd";
import {
  BookOutlined,
  CodeSandboxOutlined,
  FilterOutlined,
  KeyOutlined,
  PhoneOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Link from "next/link";
import { fetchWrapper } from "../service";

export default function LayoutDefault({ children }) {
  const formRef = React.createRef();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [activeMenu, setActiveMenu] = useState("home");
  const [info, setInfo] = useState({});

  const handleCancel = () => {
    formRef.current.resetFields();
    setIsModalVisible(!isModalVisible);
  };
  const processSidebar = () => {
    if (window.location.pathname.includes("/news/")) {
      setActiveMenu("news");
    } else if (window.location.pathname.includes("/test")) {
      setActiveMenu("test");
    } else if (window.location.pathname.includes("/product")) {
      setActiveMenu("product");
    } else if (window.location.pathname.includes("/contact")) {
      setActiveMenu("contact");
    } else {
      setActiveMenu("home");
    }
  };
  const infoLocal =
    localStorage.getItem("info") && localStorage.getItem("info") !== "null"
      ? JSON.parse(localStorage.getItem("info"))
      : {};

  const onFinish = async (values) => {
    const rsLogin = await fetchWrapper.post("login", values);
    if (rsLogin.data && rsLogin.data.success) {
      window.location.replace(`${window.location.origin}/admin`);
      message.success("Chào mừng bạn đến với " + infoLocal.name);
      localStorage.setItem(
        "permissions",
        JSON.stringify(rsLogin.data.data.permissions)
      );
      localStorage.setItem(
        "customer",
        JSON.stringify(rsLogin.data.data.customer)
      );
      localStorage.setItem("token", rsLogin.data.data.token);
      rsLogin.data.data.permissions = null;
      localStorage.setItem("user", JSON.stringify(rsLogin.data.data));
    } else {
      message.error(rsLogin.data.message);
    }
  };

  const getInfo = async (reloadCache) => {
    const t = await fetchWrapper.getCustomerInfo(reloadCache);
    setInfo(t);
  };

  useEffect(() => {
    processSidebar();
    getInfo(false);
  }, [window.location.pathname]);

  useEffect(() => {
    processSidebar();
    getInfo(true);
  }, []);

  return (
    <Col className="background">
      <Head>
        <title>{info.name}</title>
      </Head>
      <Row justify="center" className="frontend-menu">
        <Col className="menu-header">
          <Row style={{ height: "100%" }}>
            <div className="logo">
              <img src={info.logo} />
            </div>
            <Link href="/">
              <Col
                className={
                  "col_header " + (activeMenu === "home" ? "active_menu" : "")
                }
              >
                <a>
                  <CodeSandboxOutlined />
                  <span style={{ marginLeft: "10px" }}>Giới thiệu</span>
                </a>
              </Col>
            </Link>
            <Link href="/product">
              <Col
                className={
                  "col_header " +
                  (activeMenu === "product" ? "active_menu" : "")
                }
              >
                <a>
                  <FilterOutlined />
                  <span style={{ marginLeft: "10px" }}>Sản phẩm</span>
                </a>
              </Col>
            </Link>
            {["localhost", "dyc.timlathay.org"].includes(
              window.location.hostname
            ) && (
              <Link href="/test">
                <Col
                  className={
                    "col_header " + (activeMenu === "test" ? "active_menu" : "")
                  }
                >
                  <a>
                    <FilterOutlined />
                    <span style={{ marginLeft: "10px" }}>
                      Kết quả kiểm nghiệm
                    </span>
                  </a>
                </Col>
              </Link>
            )}
            <Link href="/contact">
              <Col
                className={
                  "col_header " +
                  (activeMenu === "contact" ? "active_menu" : "")
                }
              >
                <a>
                  <PhoneOutlined />
                  <span style={{ marginLeft: "10px" }}>Liên hệ</span>
                </a>
              </Col>
            </Link>
            <Link href="/news">
              <Col
                className={
                  "col_header " + (activeMenu === "news" ? "active_menu" : "")
                }
              >
                <a>
                  <BookOutlined />
                  <span style={{ marginLeft: "10px" }}>Tin tức</span>
                </a>
              </Col>
            </Link>

            {!localStorage.getItem("user") ? (
              <Col className="col_header">
                <Button
                  onClick={() => {
                    setIsModalVisible(true);
                  }}
                  type="success-gradient"
                  className="btn-login-menu"
                >
                  <UserOutlined />
                  &nbsp;ĐĂNG NHẬP
                </Button>
              </Col>
            ) : (
              <Link href="/admin">
                <Col className={"col_header "}>
                  <a>
                    <KeyOutlined />
                    <span style={{ marginLeft: "10px" }}>Quản trị</span>
                  </a>
                </Col>
              </Link>
            )}
          </Row>
        </Col>
      </Row>

      <div className="page-content">
        <main>{children}</main>
      </div>

      <Modal
        title=""
        className="modal-login"
        visible={isModalVisible}
        onCancel={handleCancel}
        width={520}
        footer={[]}
      >
        <Row justify="center" style={{ paddingTop: 12 }}>
          <img src={infoLocal.logo} height={90} />
        </Row>
        <p className="welcome">Chào mừng bạn đến với {infoLocal.name}</p>
        <div style={{ padding: "0px 35px 35px 35px" }}>
          <Form
            ref={formRef}
            className="form-login"
            name="basic"
            labelCol={{
              span: 8,
            }}
            wrapperCol={{
              span: 16,
            }}
            initialValues={{
              remember: true,
            }}
            layout="vertical"
            onFinish={onFinish}
            autoComplete="off"
          >
            <Space direction="vertical" className="full-width">
              <Form.Item
                className="form-login-username"
                label="Tài khoản"
                name="username"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập tài khoản!",
                  },
                ]}
              >
                <Input
                  size="large"
                  className="input-login"
                  placeholder="Nhập tài khoản"
                />
              </Form.Item>
              <Form.Item
                className="form-login-password"
                label="Mật khẩu"
                name="password"
                rules={[
                  {
                    required: true,
                    message: "Vui lòng nhập mật khẩu!",
                  },
                ]}
              >
                <Input.Password size="large" placeholder="Nhập mật khẩu" />
              </Form.Item>
            </Space>
            <Space>
              <Switch className="remember" defaultChecked /> Duy trì đăng nhập
            </Space>
            <Button
              type="success-gradient"
              className="btn-largest"
              htmlType="submit"
            >
              ĐĂNG NHẬP
            </Button>
          </Form>
        </div>
      </Modal>
    </Col>
  );
}

LayoutDefault.propTypes = {
  children: PropTypes.any,
};

LayoutDefault.defaultProps = {
  children: null,
};
